<template>
  <div class="connectionFee">
    <v-row>
      <v-col>
        <breadcrumb :items="breadcrumbs" />
      </v-col>
    </v-row>
    <connection-fee-table
      :connection-fee-url="connectionFeeUrl"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  mdiShapeSquarePlus,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import ConnectionFeeTable from '@/components/tables/ConnectionFeeTable.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    ConnectionFeeTable,
  },
  data() {
    return {
      showAddMeterDialog: false,
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Connection Fee',
          disabled: true,
          to: { name: 'connection-fee' },
        },
      ],
      icons: {
        mdiShapeSquarePlus,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedStation']),
    connectionFeeUrl() {
      return `connection-fees?station_id=${this.selectedStation}`
    },
  },
}
</script>
