<template>
  <div class="connectionFees">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col class="col-md-3">
              <refresh-button
                :loading="isConnectionFeeRefreshing"
                @click="refreshConnectionFees()"
              />
            </v-col>
            <v-col class="col-md-6">
              <table-filter
                :table-name="'connection fees'"
                :show-date-filter="false"
                :show-payment-status-filter="true"
                :show-month-filter="!forSpecificCustomer"
                :show-year-filter="forSpecificCustomer"
                @filter="applyFilters"
              />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col class="col-md-6 text-center">
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="activeSearchFilter"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="connectionFees"
        :loading="isConnectionFeeLoading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:progress>
          <v-progress-linear
            indeterminate
            class="mt-2"
            rounded
          ></v-progress-linear>
          <p class="text-center mt-1">
            Loading...Please wait
          </p>
        </template>
        <template
          v-slot:body="{
            items,
          }"
        >
          <tbody v-if="connectionFees.length > 0">
            <tr
              v-for="connectionFee in items"
              :key="connectionFee.id"
              :class="{ clickable: clickableRows }"
              v-on="clickableRows ? { click: () => onConnectionFeeClick(connectionFee.id) } : {}"
            >
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ connectionFee.user.account_number }}</span>
              </td>
              <td v-if="clickableRows">
                <span class="primary--text font-weight-medium">{{ connectionFee.user.name }}</span>
              </td>
              <td>{{ connectionFee.month | formatMonth }}</td>
              <td>{{ connectionFee.amount }}</td>
              <td>{{ connectionFee.connection_fee_payments_sum_amount_paid }}</td>
              <td>{{ connectionFee.connection_fee_payments_sum_credit }}</td>
              <td>
                <v-chip
                  text-color="white"
                  :class="{
                    'paid': connectionFee.status === 1,
                    'not-paid': connectionFee.status === 0,
                    'balance': connectionFee.status === 2,
                    'over-paid': connectionFee.status === 3,
                  }"
                >
                  {{ connectionFee.status | formatMeterReadingStatus }}
                </v-chip>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-show="!isConnectionFeeLoading">
              <td
                :colspan="headers.length"
                style="text-align: center"
              >
                No connection fee found
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-divider />
      <data-table-pagination
        :page="pagination.page"
        :page-count="pagination.pageCount"
        @page-change="onPageChange"
        @items-per-page-change="onItemsPerPageChange"
      />
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mdiSend } from '@mdi/js'
import SearchInput from '@/components/partials/SearchInput.vue'
import TableFilter from '../partials/table-filter/index.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import DataTablePagination from '@/components/partials/DataTablePagination.vue'
import defaultMonthFilterDate from '@/mixins/defaultMonthFilterDate'

export default {
  components: {
    SearchInput, TableFilter, RefreshButton, DataTablePagination,
  },
  mixins: [defaultMonthFilterDate],
  props: {
    connectionFeeUrl: {
      type: String,
      default: null,
    },
    clickableRows: {
      type: Boolean,
      default: true,
    },
    forSpecificCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showConfirmDeleteDialog: false,
    showConfirmResendDialog: false,
    isConfirmDeleteDialogButtonLoading: false,
    isConfirmResendDialogButtonLoading: false,
    toDelete: null,
    sortBy: ['created_at'],
    sortDesc: [true],
    pagination: {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    },
    search: '',
    headers: [
      {
        text: 'Account number',
        value: 'account_number',
        sortable: false,
      },
      {
        text: 'Customer',
        value: 'user',
        sortable: false,
      },
      {
        text: 'Month',
        value: 'month',
        sortable: false,
      },
      {
        text: 'Bill',
        value: 'amount',
      },
      {
        text: 'Amount Paid',
        value: 'connection_fee_payments_sum_amount_paid',
      },
      {
        text: 'Credit Applied',
        value: 'credit',
      },
      {
        text: 'Status',
        value: 'status',
      },
    ],
    activeSearchFilter: 'users.account_number',
    searchFilters: [
      {
        text: 'Account number',
        value: 'users.account_number',
      },
      {
        text: 'Customer',
        value: 'users.name',
      },
      {
        text: 'Bill',
        value: 'connection_fees.amount',
      },
      {
        text: 'Cost',
        value: 'connection_fees.amount',
      },
    ],
    icons: {
      mdiSend,
    },
    meterReading: {},
    connectionFees: [],
    isConnectionFeeLoading: true,
    isConnectionFeeRefreshing: false,
    filters: {
      year: '',
      month: '',
      paymentStatus: [],
    },
  }),
  computed: {
    sortOrder() {
      return this.sortDesc[0] ? 'desc' : 'asc'
    },
    refreshTable() {
      return `${this.connectionFeeUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
  },
  watch: {
    refreshTable() {
      this.getConnectionFees()
    },
    activeSearchFilter() {
      if (this.search !== '') {
        this.getConnectionFees()
      }
    },
  },
  mounted() {
    if (this.forSpecificCustomer) {
      this.filters.year = new Date().getFullYear()
    } else {
      this.filters.month = this.defaultMonthDate.format('YYYY-MM')
    }
    this.getConnectionFees()
    if (!this.clickableRows) {
      this.removeFirstTwoTableHeader()
    }
  },
  methods: {
    getConnectionFees: _.debounce(function () {
      this.isConnectionFeeLoading = true
      const status = JSON.stringify(this.filters.paymentStatus)
      axios
        .get(
          `${this.connectionFeeUrl}&year=${this.filters.year}&month=${this.filters.month}&sortBy=${this.sortBy[0]}&sortOrder=${this.sortOrder}&search=${this.search}&search_filter=${this.activeSearchFilter}&status=${status}&page=${this.pagination.page}&perPage=${this.pagination.itemsPerPage}`,
        )
        .then(response => {
          this.connectionFees = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.isConnectionFeeLoading = false
          this.isConnectionFeeRefreshing = false
        })
        .catch(error => {
          this.isConnectionFeeLoading = false
          this.isConnectionFeeRefreshing = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
    refreshConnectionFees() {
      this.isConnectionFeeRefreshing = true
      this.getConnectionFees()
    },
    onConnectionFeeClick(connectionFeeId) {
      this.$router.push({ name: 'connection-fee-details', params: { id: connectionFeeId } })
    },
    removeFirstTwoTableHeader() {
      this.headers.splice(0, 2)
    },
    onPageChange(page) {
      this.pagination.page = page
      this.getConnectionFees()
    },
    onItemsPerPageChange(itemsPerPage) {
      this.pagination.itemsPerPage = itemsPerPage
      this.getConnectionFees()
    },
    onSearchFilterChange(filter) {
      this.activeSearchFilter = filter
    },
    applyFilters(filters) {
      this.filters = filters
      this.getConnectionFees()
    },
  },
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.v-chip.not-paid {
  background: #F44336;
}
.v-chip.paid {
  background: #4CAF50;
}
.v-chip.balance {
  background: #FFA726;
}
.v-chip.over-paid {
  background: #2196F3;
}
</style>
