var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connectionFees"},[_c('v-card',[_c('v-card-title',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-md-3"},[_c('refresh-button',{attrs:{"loading":_vm.isConnectionFeeRefreshing},on:{"click":function($event){return _vm.refreshConnectionFees()}}})],1),_c('v-col',{staticClass:"col-md-6"},[_c('table-filter',{attrs:{"table-name":'connection fees',"show-date-filter":false,"show-payment-status-filter":true,"show-month-filter":!_vm.forSpecificCustomer,"show-year-filter":_vm.forSpecificCustomer},on:{"filter":_vm.applyFilters}})],1),_c('v-spacer')],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-md-6 text-center"},[_c('search-input',{attrs:{"filters":_vm.searchFilters,"active-filter":_vm.activeSearchFilter},on:{"filter-change":_vm.onSearchFilterChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.connectionFees,"loading":_vm.isConnectionFeeLoading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{staticClass:"mt-2",attrs:{"indeterminate":"","rounded":""}}),_c('p',{staticClass:"text-center mt-1"},[_vm._v(" Loading...Please wait ")])]},proxy:true},{key:"body",fn:function(ref){
        var items = ref.items;
return [(_vm.connectionFees.length > 0)?_c('tbody',_vm._l((items),function(connectionFee){return _c('tr',_vm._g({key:connectionFee.id,class:{ clickable: _vm.clickableRows }},_vm.clickableRows ? { click: function () { return _vm.onConnectionFeeClick(connectionFee.id); } } : {}),[(_vm.clickableRows)?_c('td',[_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(connectionFee.user.account_number))])]):_vm._e(),(_vm.clickableRows)?_c('td',[_c('span',{staticClass:"primary--text font-weight-medium"},[_vm._v(_vm._s(connectionFee.user.name))])]):_vm._e(),_c('td',[_vm._v(_vm._s(_vm._f("formatMonth")(connectionFee.month)))]),_c('td',[_vm._v(_vm._s(connectionFee.amount))]),_c('td',[_vm._v(_vm._s(connectionFee.connection_fee_payments_sum_amount_paid))]),_c('td',[_vm._v(_vm._s(connectionFee.connection_fee_payments_sum_credit))]),_c('td',[_c('v-chip',{class:{
                  'paid': connectionFee.status === 1,
                  'not-paid': connectionFee.status === 0,
                  'balance': connectionFee.status === 2,
                  'over-paid': connectionFee.status === 3,
                },attrs:{"text-color":"white"}},[_vm._v(" "+_vm._s(_vm._f("formatMeterReadingStatus")(connectionFee.status))+" ")])],1)])}),0):_c('tbody',[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isConnectionFeeLoading),expression:"!isConnectionFeeLoading"}]},[_c('td',{staticStyle:{"text-align":"center"},attrs:{"colspan":_vm.headers.length}},[_vm._v(" No connection fee found ")])])])]}}])}),_c('v-divider'),_c('data-table-pagination',{attrs:{"page":_vm.pagination.page,"page-count":_vm.pagination.pageCount},on:{"page-change":_vm.onPageChange,"items-per-page-change":_vm.onItemsPerPageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }